<template>
  <v-container
    fluid
    id="NegociacoesTask"
    class="pa-0"
    v-bind:style="{ 'background-color': COR_PRINCIPAL }"
  >
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page mt-3 ml-4 w-100"
        >Oportunidades
      </v-toolbar-title>
      <!-- Fim Título da página -->

      <!-- Botões de ação cabeçalho -->
      <v-toolbar-title
        class="mt-0 mb-0 ml-0 pa-0 d-flex flex-column w-100"
        dark
        elevation="0"
      >
        <div class="d-flex align-center w-100" tabindex="-1">
          <v-text-field
            id="txtBuscar"
            ref="txtBuscar"
            v-on:keyup="filtro = $event.target.value"
            prepend-inner-icon="mdi-magnify"
            autofocus
            dense
            v-model="search"
            cache-items
            class="search-input ml-4"
            v-bind:class="{ 'search-input-longo': search_input_focus }"
            flat
            dark
            label="Buscar..."
            solo-inverted
            clearable
            @focus="onFocus($event)"
            @blur="onBlur($event)"
            tabindex="-1"
          >
          </v-text-field>

          <!-- <router-link
              :to="{ name: 'PessoaCadastroNovo' }"
              class="nav-link"
              aria-current="page"
              tabindex="-1"
            >
              <v-btn class="text-white" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
                <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
                <span>Novo</span>
              </v-btn>
            </router-link> -->

          <v-btn
            class="text-white"
            :color="COR_PRINCIPAL"
            elevation="0"
            :small="isMobile"
            tabindex="-1"
          >
            <v-icon class="mr-1" color="light-blue accent-2">mdi-filter</v-icon>
          </v-btn>

          <v-btn @click="store_ModalNegociacao.step = 'ModalSelecaoPessoa'; dialogNegociacao=true" class="text-white" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
            <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
            <span>Novo</span>
          </v-btn>

          <v-btn
            class="text-white mr-2"
            :color="COR_PRINCIPAL"
            elevation="0"
            :small="isMobile"
            tabindex="-1"
          >
            <v-icon class="mr-1" color="light-blue accent-2"
              >mdi-view-headline</v-icon
            >
            <span>Opções</span>
          </v-btn>
        </div>

        <div class="w-100" tabindex="-1">
          <v-tabs
            v-if="isMobile"
            v-model="tab"
            background-color="transparent"
            dark
            class="tab-mobile mr-2 mt-n3 pa-0"
            center-active
            slider-size="2"
            tabindex="-1"
            slider-color="#D0D0D0"
            active-class="active"
          >
            <v-tab
              v-for="column in columns"
              :key="column.title"
              class="text-none"
              tabindex="-1"
            >
              {{ column.title }}
            </v-tab>
          </v-tabs>
        </div>
      </v-toolbar-title>
      <!-- Fim Botões de ação cabeçalho -->
    </v-toolbar-title>

    <!-- DIALOG - Nova Negociacao ------------------------------------------------->
    <v-dialog
        v-model="dialogNegociacao"
        transition="dialog-bottom-transition"
        max-width="440"
      >
      <v-card>
        <ModalNegociacao />
      </v-card>
    </v-dialog>
    <!-- FIM DIALOG --------------------------------------------------------------->
    
    <!-- Help -->
    <v-expansion-panels
      class="pa-4 pt-0 px-0 expansion-panel d-none"
      accordion
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="panel-header font-weight-bold"
          disable-icon-rotate
        >
          Documentos Básicos
          <template v-slot:actions>
            <v-icon color="" class="icon-help">
              mdi-help-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          Conteúdo teste
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Fim Help -->

    <!-- Tasks do Mobile -------------------------------------------------------------------------------- -->
    <v-tabs-items
      v-if="isMobile"
      v-model="tab"
      class="ma-2 mb-5 mt-0"
      style="background-color: transparent"
    >
      <v-tab-item v-for="column in columns" :key="column.title">
        <draggable
          :list="column.tasks"
          :animation="200"
          group="ghost-card tasks"
          :disabled="true"
        >
          <div v-if="column.title == 'Lead'">
            <TaskCardLead
              v-for="task in column.tasks"
              :key="task.id"
              :task="task"
              class="mt-2 cursor-move"
            ></TaskCardLead>
          </div>
          <div v-if="column.title != 'Lead'">
            <TaskCard
              v-for="task in column.tasks"
              :key="task.id"
              :task="task"
              class="mt-2 cursor-move"
            ></TaskCard>
          </div>
        </draggable>
      </v-tab-item>
    </v-tabs-items>

    <!-- Tasks do Desktop -------------------------------------------------------------------------------- -->
    <div
      v-if="!isMobile"
      class="mr-1 ml-1 min-h-screen d-flex overflow-x-scroll altura pb-3"
    >
      <!-- v-bind:style="`height:${600}px`" -->
      <div
        v-for="column in columns"
        :key="column.title"
        class="
          column-container
          bg-gray-100
          rounded-lg
          px-2
          py-2
          column-width
          mr-1
          overflow-y-auto
          altura2
        "
      >
        <p class="title-color font-weight-medium font-sans text-sm">
          {{ column.title }}
        </p>
        <draggable
          :id="`AAAAAAA__${column.title}`"
          :ref="`AAAAAAA__${column.title}`"
          :list="column.tasks"
          :animation="200"
          class="ghost-card"
          group="tasks"
          :disabled="false"
          @end="onDraggableEnd"
          :move="onDraggableMove"
          :options="{
            scroll: true,
          }"
        >
          <!-- :scroll-sensitivity="20"
            :forceFallback="true" -->
          <div v-if="column.title == 'Lead'">
            <TaskCardLead
              v-for="task in column.tasks"
              :key="task.id"
              :task="task"
              class="mt-3 cursor-move"
              style="background-color: transparent"
            ></TaskCardLead>
          </div>
          <div v-if="column.title != 'Lead'" >
            <TaskCard
              v-for="task in column.tasks"
              :key="task.id"
              :task="task"
              class="mt-3 cursor-move"
              style="background-color: transparent"
            ></TaskCard>
          </div>

        </draggable>
      </div>
    </div>

    <!-- Modal Envio de Documentos -->
    <v-row justify="center">
      <v-dialog
        v-model="dialog_documentos"
        :retain-focus="false"
        height="600px"
        min-height="600px"
        max-width="600px"
      >
        <v-card class="justify-end align-center">
          <!-- <ModalEnvioDocumentos /> -->
        </v-card>
      </v-dialog>
    </v-row>
    <!-- End Modal Envio de Documentos -->

    <!-- Modal Envio de Documentos -->
    <v-row justify="center">
      <v-dialog
        v-model="dialog_relatorio"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        :retain-focus="false"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog_relatorio = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Settings</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="dialog_relatorio = false"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

        </v-card>
      </v-dialog>
    </v-row>
    <!-- End Modal Envio de Documentos -->

    <!-- <div id="container">
      <a  id="nav" target="_blank" href="#"> click to navigate </a> <br />
      <iframe src="" name="i_frame" id="i_frame" style="width:100%; height:600px"> </iframe>
    </div> -->

    <!-- <router-link :to="{ path: `${baseURL}rel`}" target="_blank">
      Текст
    </router-link> -->

    <!-- <a :href="$router.push({name: 'Rel1', params: {post: 1}}).href" target="_blank">
      Open Post in new tab
    </a> -->

    <!-- <form action="http://localhost:3003/index2" type="POST" >
        <input type="hidden" value="TOKEN" name="token">
        <input type="hidden" value="TESTANDO" name="testando">
        <button type="submit">
          ENVIAR
        </button>
    </form>     -->
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { COR_PRINCIPAL } from "../../services/constantes";
import store_Negociacoes from "../Negociacoes/store_Negociacoes";
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import TaskCard from "./TaskCard.vue";
import TaskCardLead from "./TaskCardLead.vue";
import ModalNegociacao from "../ModalNegociacao/ModalNegociacao.vue";

export default {
  name: "NegociacoesTask",

  components: {
    draggable,
    TaskCard,
    TaskCardLead,
    ModalNegociacao,
  },

  data() {
    return {
      store_Negociacoes     : store_Negociacoes,
      store_ModalNegociacao : store_ModalNegociacao,

      dialogNegociacao      : false,
      dialog_documentos     : false,
      loading               : 0,
      mostra                : false,
      url_relatorio         : "",
      dialog_relatorio      : false,
      relatorio             : null,
      open                  : false,
      msg                   : "Testando Nova Página",
      vhtml                 : "",
      enabled               : false,
      tab                   : null,
      search                : null,
      search_input_focus    : false,
      COR_PRINCIPAL         : COR_PRINCIPAL,

      fechamento_abertos: [],
      lead_abertas: [],
      qualificacao_abertas: [],
      reserva_abertas: [],
      proposta_abertas: [],
      aba_selecionada: [],

      dragOptions() {
        return {
          group: {
            name: "g1",
          },
          scrollSensitivity: 200,
          forceFallback: true,
        };
      },

      columns: [],
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },

  mounted() {
    //console.log("MOUNTED NegociacoesTasks");
    this.abre();
  },

  methods: {
    async abre() {
      await this.store_Negociacoes.filtra_negociacoes_abertas();

      // Filtra de forma distinct pelo elemento do item
      //const unique = [...new Set(la_dados.map(item => item.fase_descricao))];
      const unique = ['Lead',
                      'Qualificação',
                      'Reserva',
                      'Proposta',
                      'Fechamento'];

      unique.map((value) => {
        if (value == 'Lead') {
          this.columns.push({ title: value,
                              tasks:[
                                    {
                                    "tipo_pessoa": "CLIENTE",
                                    "cod_opor": 1000,
                                    "cod_orcamento": null,
                                    "cod_usuario": 1,
                                    "fase_descricao": "Lead",
                                    "situacao": "Aguardando",
                                    "data_hora": "2021-07-20T21:11:20.000Z",
                                    "fase_sigla": "L",
                                    "cod_pessoa": 907,
                                    "ultima_fase": "SIM",
                                    "cod_fase": 1,
                                    "pessoa_nome": "FERNANDO GARCIA",
                                    "empreend_nome": null,
                                    "quadra": null,
                                    "lote": null,
                                    "area_metro": null,
                                    "preco_total": null,
                                    "imagem_caminho": "/imagens/imoveis/fotos/empresa_1/005509/005509_000001.png"
                                    }
                                    ]
                            })
        }
        else if (value == 'Qualificação') {
          this.columns.push({ title: value,
                              tasks:[
                                    {
                                    "tipo_pessoa": "CLIENTE",
                                    "cod_opor": 1001,
                                    "cod_orcamento": null,
                                    "cod_usuario": 1,
                                    "fase_descricao": "Qualificação",
                                    "situacao": "Aguardando",
                                    "data_hora": "2021-07-20T21:11:20.000Z",
                                    "fase_sigla": "Q",
                                    "cod_pessoa": 907,
                                    "ultima_fase": "SIM",
                                    "cod_fase": 1,
                                    "pessoa_nome": "BRUNO DUARTE",
                                    "empreend_nome": "JARDIM BOTÂNICO",
                                    "quadra": "03",
                                    "lote": "30",
                                    "area_metro": 300.05,
                                    "preco_total": 180700.50,
                                    "imagem_caminho": "/imagens/imoveis/fotos/empresa_1/005660/005660_000001.png"
                                    }
                                    ]
                            })
        }        
        else if (value == 'Fechamento') {
          this.columns.push({ title: value,
                              tasks:[
                                    {
                                    "tipo_pessoa": "CLIENTE",
                                    "cod_opor": 1002,
                                    "cod_orcamento": null,
                                    "cod_usuario": 1,
                                    "fase_descricao": "Fechamento",
                                    "situacao": "Aguardando",
                                    "data_hora": "2021-07-20T21:11:20.000Z",
                                    "fase_sigla": "Q",
                                    "cod_pessoa": 907,
                                    "ultima_fase": "SIM",
                                    "cod_fase": 1,
                                    "pessoa_nome": "BRUNO DUARTE",
                                    "empreend_nome": "JARDIM BOTÂNICO",
                                    "quadra": "03",
                                    "lote": "30",
                                    "area_metro": 300.05,
                                    "preco_total": 180700.50,
                                    "imagem_caminho": "/imagens/imoveis/fotos/empresa_1/005660/005660_000001.png"
                                    }
                                    ]
                            })
        }        
        else
          this.columns.push({ title: value,
                              tasks: this.store_Negociacoes.negociacoes.filter((o) => o.fase_descricao == value),
          })
                                 
      });
      // this.columns = [];
      // this.lead_abertas = {
      //   title: "Lead",
      //   tasks: la_dados.filter((o) => o.fase_descricao == "Lead"),
      // };
      // this.qualificacao_abertas = {
      //   title: "Qualificação",
      //   tasks: la_dados.filter((o) => o.fase_descricao == "Qualificação"),
      // };
      // this.reserva_abertas = {
      //   title: "Reservas",
      //   tasks: la_dados.filter((o) => o.fase_descricao == "Reserva"),
      // };
      // this.proposta_abertas = {
      //   title: "Proposta",
      //   tasks: la_dados.filter((o) => o.fase_descricao == "Proposta"),
      // };
      // this.fechamento_abertos = {
      //   title: "Fechamento",
      //   tasks: la_dados.filter((o) => o.fase_descricao == "Fechamento"),
      // };
      // this.columns.push(this.lead_abertas);
      // this.columns.push(this.qualificacao_abertas);
      // this.columns.push(this.reserva_abertas);
      // this.columns.push(this.proposta_abertas);
      // this.columns.push(this.fechamento_abertos);
      // console.log(this.columns);
    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },

    onDraggableEnd(value) {
      //   // value.preventDefault();
      //   if (value.to.id == 'AAAAAAA__Feito') {
      //     this.$refs.AAAAAAA__Feito.disabled = true;
      //     console.log("onDraggableEnd - AAAAAAA__Feito ", value.from.id, value.to.id)
      //  }
    },
    onDraggableMove(value) {
      if (
        (value.from.id == "AAAAAAA__Feito" &&
          value.to.id != "AAAAAAA__Feito") ||
        (value.from.id != "AAAAAAA__Feito" && value.to.id == "AAAAAAA__Feito")
      ) {
        console.log(
          "onDraggableMove - AAAAAAA__Feito ",
          value.from.id,
          value.to.id
        );
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/vue-kanban/src/assets/kanban.scss";
</style>

<style lang="scss" scoped>
*,
html {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none !important;
  overflow-y: hide;
}

.title-page {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  font-size: 20px;
}

.title-color {
  color: var(--COR_PRINCIPAL) !important;
}

.active {
  /* color: rgba(255, 130, 130, 1); */
  /* background-color: rgba(255, 130, 130, 1); */
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(255, 255, 255, 0.35);
}

// div .main-title,
// .metreage {
//   font-weight: 600;
//   font-size: 1.3rem !important;
//   font-family: "Montserrat", sans-serif;
// }

// .main-title::after {
//   display: block;
//   width: 50px;
//   height: 3px;
//   margin-top: 0.5rem;
//   content: "";
//   background: #274abb;
// }

.text-white {
  color: #fff;
}

// .v-tab__--active {
//   color: green;
// }

// @media (max-width: 1024px) {
//   .title-wrapper {
//     margin-left: 0px !important;
//   }
// }

// @media (max-width: 599px) {
//   .title-wrapper {
//     padding-left: 0px !important;
//     margin-left: 0px !important;
//   }
// }

// .wrapper {
//   max-width: 1200px !important;
//   background: #e0e0e0 !important;
// }

// .tab-item {
//   background: #e0e0e0 !important;
// }

// .col-first-container {
//   flex-direction: column !important;
// }

/*-----------------------------------*/
/*------ MINHAS NEGOCIAÇõES ------*/
/*-----------------------------------*/
// .card-column {
//   box-sizing: border-box !important;
//   padding: 10px !important;
//   display: flex;
//   justify-content: center;
// }

// @media (max-width: 599px) {
//   .card-column {
//     padding-right: 0px !important;
//     padding-left: 0px !important;
//   }
// }

// @media (max-width: 1024px) {
//   .main-container {
//     margin-left: 0px !important;
//     padding: 0px !important;
//   }

//   .card {
//     margin: auto 0px !important;
//   }

// //   .col-situacao {
// //     margin-left: 0px !important;
// //   }
// }

// .card-row {
//   display: flex;
//   box-sizing: border-box !important;
//   padding: 16px;
// }

// .card-title {
//   font-size: 17px !important;
//   font-weight: 600;
//   color: #444;
// }

// @media (max-width: 768px) {
//   .footer-value {
//     flex-direction: column !important;
//   }
// }

// @media (max-width: 599px) {
//   .footer-value {
//     flex-direction: row !important;
//   }
// }

// .action-btn {
//   width: 220px !important;
// }

// .v-select__selection {
//   display: none !important;
// }

// .select {
//   color: #fff;
// }

// .modal-title {
//   background: #0548a3;
// }

// .modal-title {
//   color: rgba(225, 225, 225, 0.95);
// }

// .text-intro {
//   font-family: "Montserrat", sans-serif !important;
//   font-size: 14px !important;
//   font-weight: 500;
// }

// @media (max-width: 599px) {
//   .text-intro {
//     font-size: 12px !important;
//     padding-right: 10px !important;
//     padding-left: 10px !important;
//   }
// }

// @media (max-width: 414px) {
//   .text-intro {
//     font-size: 12px !important;
//     padding-right: 25px !important;
//     padding-left: 25px !important;
//   }
//   .show-375 {
//     display: inline !important;
//   }
// }

// @media (max-width: 375px) {
//   .card-table {
//     margin-right: 0px !important;
//     margin-left: 0px !important;
//   }
// }

// .col-avatar {
//   padding-right: 0px;
// }

// .col-empreendimento,
// .col-quadra,
// .col-situacao {
//   padding-left: 0px;
// }

// .subtitle {
//   color: #3858c0;
// }

// .col-list {
//   padding-right: 0px;
// }

// .icon-help {
//   position: absolute;
//   right: 15px;
//   font-size: 28px;
// }

// .btn-container {
//   margin-left: -50px;
// }

// @media (max-width: 768px) {
//   .container-emp-mobile {
//     margin-bottom: 20px !important;
//   }

//   .container-empreendimento span {
//     display: flex;
//   }

//   .col-situacao {
//     align-items: flex-start !important;
//     flex-direction: column !important;
//     margin-left: 4px !important;
//     padding-left: 0px !important;
//   }

//   .col-btn {
//     justify-content: center !important;
//   }

//   .btn-container {
//     flex-direction: row !important;
//     justify-content: flex-start !important;
//     max-width: 100% !important;
//     flex-wrap: wrap;
//   }

//   .btn-container .action-btn {
//     margin-right: 16px;
//   }
// }

// @media (max-width: 599px) {
//   .container-emp-mobile {
//     margin-bottom: -20px !important;
//   }

//   .col-btn,
//   .col-status {
//     display: none !important;
//   }
// }

// .action-btn,
// .action-btn-mobile {
//   font-size: 12px;
//   color: #fff;
// }

// .send-button {
//   width: 100%;
// }

// .send-button:hover {
//   background: #0548a3;
//   color: #fff !important;
// }

// .icon-btn {
//   font-size: 20px;
// }

// @media (max-width: 599px) {
//   .title-wrapper {
//     padding-top: 15px;
//   }

//   .card {
//     padding: 10px !important;
//     margin: 0px !important;
//   }

//   .main-container {
//     margin: 0px !important;
//     box-sizing: border-box;
//     padding: 0px 10px !important;
//   }

//   .col-container {
//     margin-bottom: 16px;
//   }

//   .col-first-container {
//     flex-direction: column !important;
//   }

//   .negociacao-mobile {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     padding-top: 6px;
//     padding-bottom: 10px !important;
//     border-bottom: 1px solid rgba(225, 225, 225, 0.95);
//     box-sizing: border-box;
//   }

//   .col-negociacao {
//     display: none !important;
//   }

//   .icon-help {
//     position: absolute;
//     right: 15px;
//   }

//   .margin-mobile {
//     margin-bottom: 10px;
//   }

//   .col-list {
//     flex-direction: column !important;
//   }

//   @media (max-width: 599px) {
//     .col-list {
//       padding-top: 15px !important;
//     }
//   }

//   .container-aguardando {
//     flex-direction: row !important;
//     margin-top: 8px !important;
//   }

//   .container-empreendimento {
//     margin-top: 0px !important;
//   }

//   .col-empreendimento {
//     display: flex;
//   }
// }

// @media (max-width: 375px) {
//   .title-wrapper {
//     padding-left: 0px !important;
//   }
//   .col-avatar {
//     padding-right: 15px !important;
//   }
//   .col-list {
//     padding-left: 40px;
//   }

//   .col-cronometro {
//     padding-left: 4px !important;
//   }
// }

// @media (max-width: 1024px) {
//   .homes-tag {
//     width: 136px !important;
//     margin-top: 50px !important;
//   }
// }

// @media (max-width: 768px) {
//   .homes-tag {
//     width: 128px !important;
//     margin-top: 10px !important;
//   }
// }

// @media (max-width: 599px) {
//   .homes-tag {
//     width: 98px !important;
//     margin-top: 50px !important;
//   }
// }

// @media (max-width: 599px) {
//   .breakpoint-1024 {
//     display: none !important;
//   }

//   .breakpoint-1440-proposta {
//     display: none !important;
//   }
// }

// @media (max-width: 375px) {
//   .col-situacao-mobile {
//     padding-left: 4px;
//   }
// }

/*-----------------------------------*/
/*------ TABS ------*/
/*-----------------------------------*/
// .v-tabs {
//   max-width: 1310px;
//   margin: auto;
// }

// @media (max-width: 1024px) {
//   .v-tabs {
//     max-width: 960px;
//   }
// }

// @media (max-width: 768px) {
//   .v-tabs {
//     max-width: 704px;
//   }
// }

// @media (max-width: 599px) {
//   .v-tabs {
//     padding: 0px !important;
//   }
// }

// .tab-orcamento,
// .tab-proposta,
// .tab-reserva {
//   width: 150px;
//   font-size: 0.3rem !important;
//   border-radius: 8px;
//   background: #f7f7f7ea;
// }

// .tab-orcamento span,
// .tab-reserva span,
// .tab-proposta span {
//   text-align: left;
//   width: 100%;
// }

// .wrapper-tabs {
//   width: 100%;
// }

// .tab-text {
//   font-size: 1.6rem !important;
//   position: relative;
//   top: 0px;
// }

// .tabs-icon {
//   font-size: 2rem !important;
//   float: right;
//   /* margin-left: 50px!important; */
// }

// .nav-link {
//   color: #a7a9aa;
// }

// .column-width {
//   min-width: 320px!important;
//   width: 320px!important;
//   background: #e8e8e8;
// }

.column-container {
  background-color: #e9e9e9;
  width: 350px !important;
  min-width: 350px !important;
}

.altura {
  height: calc(100vh - 173px) !important;
}

@media(max-width: 599px) {
  .tab-mobile {
  margin-left: -30px!important;
  } 
}

@media(max-width: 375px) {
  .tab-mobile {
    margin-left: -44px!important;
  } 
}


/* Unfortunately @apply cannot be setup in codesandbox, 
  but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.8 !important;
  background: #e8e8e8 !important;
  /* border: 1px solid #4299e1!important; */
}

@media (max-width: 599px) {
  .ghost-card {
    opacity: 0.7 !important;
    background: #e8e8e8 !important;
    /* border: 1px solid #4299e1!important; */
  }
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.cursor-move {
  cursor: move;
}

// ==================================================================================================================
// ==================================================================================================================
// ==================================================================================================================

/*-----------------------------------*/
/*------ TABLE ------*/
/*-----------------------------------*/
.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.accordion-panel {
  box-shadow: none !important;
}

.expansion-panel {
  border-radius: 8px 8px 0px 0px;
  border-radius: 25px 25px 0px 0px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-btn {
  display: inline-block;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/

// .wrapper-title,
// .v-toolbar,
// .v-sheet {
//   display: flex !important;
//   flex-direction: column !important;
//   padding: 0px !important;
// }

// .v-toolbar__content {
//   padding-left: 0px !important;
// }

// .v-toolbar__title {
//   padding-right: 0px !important;
//   padding-left: 0px !important;
// }

// .v-input__control {
//   background: #2a63b1 !important;
// }

.search-input {
  margin: 0.8rem 10% 1.2rem 0;
  height: 35px !important;
}
.search-input-longo {
  // margin: 0.8rem 10% 1.2rem 0;
  // height: 35px !important;
  // min-width: calc(100% - 15px);
}

// @media (max-width: 768px) {
//   .search-input {
//     margin: 0.8rem 10% 1.2rem 0;
//   }
//   .search-input-longo {
//     margin: 0.8rem 10% 1.2rem 0;
//     min-width: calc(100% - 15px);

//   }
// }

// .v-input__control,
// .v-input__slot,
// .v-select__slot {
//   height: 35px !important;
// }

// .v-label {
//   margin-right: 0px !important;
// }

// .icon-action {
//   font-size: 1.3rem !important;
// }

// @media (max-width: 768px) {
//   .btn-icon {
//     margin-right: 8px !important;
//   }
// }

// @media (max-width: 768px) {
//   .main-title {
//     padding-right: 0px !important;
//     padding-left: 0px !important;
//   }

//   .container-content {
//     max-width: 740px !important;
//   }
// }

// .container-principal {
//   max-width: calc(100% - 32px) !important;
//   margin: auto !important;
// }

// .wrapper-title div {
//   padding-left: 0px !important;
// }

// .container-rounded {
//   background: #fff !important;
//   border-radius: 12px !important;
// }

// #virtual-scroll-table-tablet {
//   display: none;
// }

// @media (max-width: 1024px) {
//   .td-avatar {
//     padding-left: 16px !important;
//   }

//   .td-edit,
//   .nav-link {
//     padding-right: 0px !important;
//   }

//   .status_ativo,
//   .status_pendente {
//     margin-right: 4px !important;
//   }
// }

/* CELULAR */
@media (max-width: 599px) {
  //   .container,
  //   .container-principal {
  //     margin: 0px !important;
  //     padding: 0px 0px 0px 0px !important;
  //   }

  //   .container-rounded {
  //     background: #FFF !important;
  //     border-radius: 12px !important;
  //     max-width: calc(100%) !important;
  //     margin: auto !important;
  //   }

  //   .title-page {
  //     margin-left: 16px !important;
  //   }

  //   .wrapper-title div {
  //     padding-left: 0px !important;
  //     margin-left: -8px;
  //   }

  //   .nav-link {
  //     padding-left: 0px;
  //   }

  //   .theme--light.v-data-table {
  //     border-radius: 12px 12px 0px 0px !important;
  //   }

  //   .avatar {
  //     margin: 0px 0px 0px 0px !important;
  //   }

  //   .td-edit-mobile {
  //     padding-right: 12px !important;
  //     padding-left: 0px !important;
  //   }

  //   .status-mobile {
  //     display: block;
  //   }

  //   .td-status {
  //     display: none;
  //   }

  .btn-icon {
    margin-right: 0px !important;
  }
  .search-input {
    margin-right: 10px !important;
    margin: 0.8rem 0 1.2rem 0px;
    min-width: auto;
  }
  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    // min-width: calc(100% - 30px);
  }
}

  .btn {
    width: 120px!important;
  }

.column-container::-webkit-scrollbar {
    width: 5px;
}

.column-container::-webkit-scrollbar-button {
  padding: 1px;
  /* height: 150px; */
}

.column-container::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.column-container::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
}  

</style>
