<template>
  <v-container fluid id="TaskCard" class="pa-0" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
    <v-card class="px-3 py-3" elevation="2">

      <div :style="textNomeColor" class="d-flex justify-space-between">
        <span class="font-cod_opor mt-n1">{{task.cod_opor}}</span>
        <!-- <div class="text-small"><v-icon class="icon-size mr-1" style="color: #C3C3C3;">mdi-calendar-blank-outline</v-icon>{{ moment(task.data_hora).format('DD/MM/YYYY HH:mm') }}</div> -->
        <v-icon class="icon-card pl-2">mdi-dots-horizontal</v-icon>
      </div>

      <!-- DADOS CLIENTE ------------------------------------------------>
      <v-expansion-panels class="expand-client" flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header class="pa-0"> 
            <div class="d-flex align-center">
              <!-- CLIENTE OU CORRETOR ------------------------------------------------>
              <!-- <v-avatar
              class="mr-2"
              color="primary"
              size="25">
                <img
                  class="w-6 h-6 ml-3"
                  src="https://pickaface.net/gallery/avatar/unr_sample_161118_2054_ynlrg.png"
                  alt="Avatar"
                >
              </v-avatar> -->
              <!-- FIM CLIENTE OU CORRETOR ------------------------------------------------>
              <div :style="textNomeColor" class="caption">{{ task.pessoa_nome }}</div>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="pt-2 ml-n6">
            <div class="text-small"><v-icon class="icon-size" style="color: #C3C3C3;">mdi-email-outline</v-icon> <span style="color: #1E1E1E;">{{task.pessoa_email}}</span></div>
            <div class="text-small"><v-icon class="icon-size" style="color: #C3C3C3;">mdi-phone-outline</v-icon> <span style="color: #1E1E1E;">{{task.fone_celular1}}</span></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- FIM DADOS CLIENTE ------------------------------------------------>

      <!-- DADOS EMPREENDIMENTO ------------------------------------------------>
      <div class="d-flex mt-4">
        <div>
          <v-avatar
          class="rounded mr-2"
          color="primary"
          size="100"
          tile>
            <img
              class="w-6 h-6"
              v-bind:src="imagem_caminho(task.imagem_caminho)"
              alt="Avatar"
            >
          </v-avatar>
        </div>

        <div>
          <div class="empreendimento font-weight-bold text-capitalize text-small mb-1">{{ task.empreend_nome }}</div>
          <div class="text-small2" style="color: #989898;">Quadra: <span style="color: #1E1E1E;">{{ task.quadra }}</span> / Lote: <span style="color: #1E1E1E;">{{ task.lote }}</span></div>
          <div class="text-small2" style="color: #989898;">Área: <span style="color: #1E1E1E;">{{ task.area_metro }}</span></div>
          <div class="text-preco body-2 mt-1">R$ {{ formatNumber(task.preco_total) }}</div>
        </div>
      </div>
      <!-- FIM DADOS EMPREENDIMENTO ------------------------------------------------>

      <!-- Prograss bar da Reserva -------------------------- -->
      <div 
        v-if="task.fase_descricao == 'Reserva'"
        class="mt-4">

        <v-progress-linear
          v-model="progress"
          class="mb-2"
          rounded
          :color="cor_progress(progress)"
          height="20"
        >
          <template v-slot:default="{ value }">
            <strong v-if="!value">{{ Math.ceil(value) }}%</strong>
            <span class="text-small">reserva vence em 2 dias e 2 horas</span>
          </template>
        </v-progress-linear>
      </div>

      <div class="mt-2">
        <span class="caption"><v-icon class="icon-size mr-1" style="color: #C3C3C3;">mdi-calendar-blank-outline</v-icon>{{ moment(task.data_hora).format('DD/MM/YYYY HH:mm') }}</span> 
      </div>

    </v-card>
  </v-container>
</template>
<script>
//import Badge from "./Badge.vue";
import moment from "moment";
import { COR_PRINCIPAL } from "../../services/constantes";
import { formatNumber } from "../../services/funcoes";

export default {
  name: "TaskCard",
  
  components: {
    //Badge,
  },

  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      moment          : moment,
      formatNumber    : formatNumber,
      progress        : Math.floor(Math.random() * 100),
      COR_PRINCIPAL   : COR_PRINCIPAL,
    }
  },

  computed: {
    textNomeColor() {
      return {
        "text-transform" : "capitalize !important",
        "font-weight" : "500",
        "font-size"   : "0.90rem !important",
        "color"       : this.COR_PRINCIPAL,
        //height: `${this.height}px`
      };
    },

    badgeColor() {
      const mappings = {
        Design: "purple", "Feature Request": "teal",
        Backend: "blue",
        QA: "green",
        default: "teal",
      };
      return mappings[this.task.type] || mappings.default;
    },
  },

  methods: {
    imagem_caminho(value) {
      return 'http://localhost:3002/' + value
    },

    cor_progress(value) {
      if (value <= 15)
        return 'green'
      if (value >= 16 && value <= 50)
        return 'yellow'
      if (value >= 51 && value <= 80)
        return 'orange'
      if (value >= 81)
        return 'red'
    }
  }
  
};
</script>

<style scoped>
.text-small {
  font-weight: 500;
  font-size: 0.8rem !important;
}

.text-small2 {
  font-weight: 500;
  font-size: 0.9rem !important;
}

.font-cod_opor {
  font-weight: 600;
  font-size: 1rem !important;
}

.text-preco {
  font-weight: 600;
  font-size: 1rem !important;
  color:darkcyan;
}

.expand-client {
 border-bottom: 1px solid #E9E9E9;
}

.empreendimento {
  line-height: 1rem;
}

.icon-card {
  cursor: pointer;
}

.icon-size {
  margin-top: -4px;
  font-size: 1.2rem;
}
</style>
