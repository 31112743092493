<template>
  <v-container fluid id="TaskCard" class="pa-0" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
    <v-card class="px-3 py-3" elevation="2">

      <div :style="textNomeColor" class="d-flex justify-space-between">
        <span class=" font-cod_opor mt-n1">{{task.cod_opor}}</span>
        <v-icon class="icon-card pl-2">mdi-dots-horizontal</v-icon>
      </div>

      <div class="d-flex mt-2">
        <div>
          <v-avatar
          class="mr-2"
          color="primary"
          size="50">
            <img
              class="w-6 h-6 ml-3"
              src="https://pickaface.net/gallery/avatar/unr_sample_161118_2054_ynlrg.png"
              alt="Avatar"
            >
          </v-avatar>
        </div>

        <div>
          <div class="text-name font-weight-bold text-capitalize text-small mb-1" :style="textNomeColor">{{ task.pessoa_nome }}</div>
          <div class="text-small" ><v-icon class="icon-size" style="color: #C3C3C3;">mdi-email-outline</v-icon> <span style="color: #1E1E1E;">fernando@viscom.com.br</span></div>
          <div class="text-small" ><v-icon class="icon-size" style="color: #C3C3C3;">mdi-phone-outline</v-icon> <span style="color: #1E1E1E;">16 99999-0000</span></div>
        </div>
      </div>

      <div class="mt-2">
        <span class="caption"><v-icon class="icon-size mr-1" style="color: #C3C3C3;">mdi-calendar-blank-outline</v-icon>{{ moment(task.data_hora).format('DD/MM/YYYY HH:mm') }}</span> 
      </div>
    </v-card>
  </v-container>
</template>
<script>
//import Badge from "./Badge.vue";
import moment from "moment";
import { COR_PRINCIPAL } from "../../services/constantes";
import { formatNumber } from "../../services/funcoes";

export default {
  name: "TaskCard",
  
  components: {
    //Badge,
  },

  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      moment          : moment,
      formatNumber    : formatNumber,

      COR_PRINCIPAL   : COR_PRINCIPAL,

    }
  },

  computed: {
    textNomeColor() {
      return {
        "text-transform" : "capitalize !important",
        "font-weight" : "500",
        "font-size"   : "0.90rem !important",
        "color"       : this.COR_PRINCIPAL,
        //height: `${this.height}px`
      };
    },

    badgeColor() {
      const mappings = {
        Design: "purple", "Feature Request": "teal",
        Backend: "blue",
        QA: "green",
        default: "teal",
      };
      return mappings[this.task.type] || mappings.default;
    },
  },
};
</script>

<style scoped>
.text-small {
  font-weight: 500;
  font-size: 0.9rem !important;
}

.text-small2 {
  font-weight: 500;
  font-size: 0.9rem !important;
}

.font-cod_opor {
  font-weight: 600;
  font-size: 1rem !important;
}

.text-preco {
  font-weight: 600;
  font-size: 1rem !important;
  color:darkcyan;
}

.text-name {
  line-height: 1rem;
}

.icon-card {
  cursor: pointer;
}

.icon-size {
  margin-top: -4px;
  font-size: 1.2rem;
}

.caption {
  color: #6EADE2;
}
</style>
